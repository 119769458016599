import React from "react"

import About from "../components/About"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="About Thesis | A digital agency"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description="Thesis began as an email marketing agency (eROI) but has evolved into a full-service digital agency—creative, marketing, strategy. All the digital things. Learn more."
    />
    <About />
  </Layout>
)

export default AboutPage
