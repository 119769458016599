import React from "react"
import Eyebrow from "../Eyebrow"
import Sgif from "./images/thesis_capabilities_V2.gif"
import styles from "./styles.module.less"

const Services = () => (
  <>
    <section id="services" className={styles.Services}>
      <div className={styles.graphic}>
        <img src={Sgif} className={styles.shortBox}></img>
      </div>
      <div className={styles.header}>
        <Eyebrow>Our Services</Eyebrow>
        <div className={styles.subhead}>
          <h2>How to reach your audience in a mad, mad world*.</h2>
        </div>
      </div>
      <div className={styles.serviceDescriptions}>
        <div className={styles.service}>
          <h2>Partner Services</h2>
          <h3>
            Ride-or-die partners.
            <br /> Mutually-invested advocates.
            <br /> Stewards of success stories.
          </h3>
          <ul>
            <li>Account management</li>
            <li>Project management</li>
            <li>Process management</li>
            <li>Operational solutions</li>
          </ul>
        </div>
        <div className={styles.service}>
          <h2>Creative</h2>
          <h3>
            Smart written and visual work.
            <br /> Collaboration over competition.
            <br /> Execution over ego.
          </h3>
          <ul>
            <li>Digital product design</li>
            <li>Responsive UX/UI</li>
            <li>Prototyping</li>
            <li>Brand strategy &amp; design</li>
            <li>Digital marketing</li>
            <li>Copywriting &amp; content creation</li>
            <li>Identity</li>
            <li>Web design</li>
            <li>Digital design</li>
            <li>Motion graphics</li>
          </ul>
        </div>
        <div className={styles.service}>
          <h2>Technology</h2>
          <h3 className={styles.serviceDescription}>
            Employing today’s technologies.
            <br />
            Needed solutions&#8211;
            <br />
            Never needless tech debt.
          </h3>
          <ul>
            <li>HTML email </li>
            <li>Websites</li>
            <li>Web applications</li>
            <li>Mobile applications</li>
            <li>High-fidelity prototypes / wireframes</li>
          </ul>
        </div>
        <div className={styles.service}>
          <h2>Strategy</h2>
          <h3 className={styles.serviceDescription}>
            Insight-driven strategies.
            <br /> Connecting consumers to brands.
            <br /> Always driving business.
          </h3>
          <ul>
            <li>Consumer research</li>
            <li>Account Based Marketing (ABM)</li>
            <li>Demand Generation</li>
            <li>Digital brand planning</li>
            <li>Integrated tech solutions</li>
            <li>Digital</li>
            <li>Content</li>
            <li>Media</li>
            <li>Mobile</li>
            <li>Website</li>
            <li>SEO</li>
          </ul>
        </div>
      </div>
    </section>
  </>
)

export default Services
