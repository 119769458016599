import React from "react"
import Eyebrow from "../Eyebrow"
import Slide from "react-reveal/Slide"
import Reveal from "react-reveal/Reveal"
import Img from "../../../ResponsiveImg"

import partners from "./img/clients.gif"
import styles from "./styles.module.less"

const Story = () => (
  <div className={styles.Story}>
    <section className={styles.header}>
      <Slide bottom>
        <Eyebrow>Our Story</Eyebrow>
        <h2>
          What&rsquo;s in a<br /> name&#42;?
        </h2>
      </Slide>
      <div className={styles.collage}>
        <Collage />
      </div>

      <div className={styles.rightAlign}>
        <Slide bottom>
          <h2 className={styles.leftAlign}>
            &nbsp;Almost
            <br /> everything.
          </h2>
        </Slide>
      </div>
    </section>
    <section className={styles.storyMain}>
      <Reveal>
        <div className={styles.firstBox}>
          <p className={styles.firstParagraph}>
            <em>Email</em> was our first word. In fact, eROI spent more than a
            decade making inboxes better. And we still do. But lately, we’ve
            been up to <a href="#services">much more</a>.
          </p>
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.secondBox}>
          <p>
            <strong>Our partners</strong> (some call them clients) need to
            connect with their audiences in so many other ways. <em>Email</em>,
            yes, for sure, all day long, but also web projects, social feeds,
            wearables—the list grows and grows.
          </p>
          <img className={styles.partners} src={partners} />
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.thirdBox}>
          <p>
            <strong>Our teams</strong> recognize this and have been growing in
            stride … for years now. Embracing shifts and making meaningful,
            digital connections where. ever. possible.
          </p>
          <p>
            You see, we’ve functioned above and beyond <em>email</em> for awhile
            now. So why call eROI something we aren’t ANYMORe?
          </p>
          <p>Let’s not.</p>
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.fourthBox}>
          <br />
          <br />
          <p className={styles.statement}>
            Let’s make a statement supporting this maturation—this movement
            toward unified digital experiences by way of intentionally-ironclad
            strategies, you-just-blew-my-mind creative, and modern,
            this-porridge-is-just-right technologies.
            <br />
            <br />
            Let’s introduce the agency we already are—an agency that’s here to
            make statements in this world and back them to the teeth.
          </p>
          <div className={styles.footnote}>
            <p>&#42;An agency called Thesis.</p>
          </div>
        </div>
      </Reveal>
    </section>
  </div>
)

export default Story

const Collage = () => (
  <Img src="/images/about-us-thesis-sketches-desktop@3x.png" width="1000" />
)
