import React, { Component } from "react"

import Reveal from "react-reveal/Reveal"

import Story from "./components/Story"
import Mission from "./components/Mission"
import Services from "./components/Services"
import Footer from "./components/Footer"
import styles from "./styles.module.less"

export default class About extends Component {
  render() {
    return (
      <div className={styles.About}>
        <Reveal>
          <Story />
        </Reveal>
        <Reveal>
          <Mission />
        </Reveal>
        <Reveal>
          <Services />
        </Reveal>
        <Footer />
      </div>
    )
  }
}
