import React from "react"
import styles from "./styles.module.less"

const Footer = () => (
  <div className={styles.footnote}>
    <p>&#42;And vice versa.</p>
  </div>
)

export default Footer
