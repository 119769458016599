import React, { Component } from "react"
import Eyebrow from "../Eyebrow"
import styles from "./styles.module.less"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import Pagination from "./Pagination"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const style = {
  root: {
    position: "absolute",
  },
  slide: {
    padding: 45,
    minHeight: 100,
    color: "#000",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
}

class Slides extends React.Component {
  state = {
    index: 0,
  }

  handleChangeIndex = index => {
    this.setState({
      index,
    })
  }

  render() {
    const { index } = this.state

    return (
      <div className={styles.CarouselBorder}>
        <div style={styles.root}>
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
            enableMouseEvents
            interval={6000}
          >
            <div style={Object.assign({}, style.slide)}>
              <Eyebrow>Our Values</Eyebrow>
              <h2>Be lean, take action.</h2>
              <h2>Connect, conflict, and embrace.</h2>
              <h2>Make your case, with data.</h2>
            </div>
            <div style={Object.assign({}, style.slide)}>
              <Eyebrow>Our Vision</Eyebrow>
              <h2>Real people</h2>
              <h2>
                <i>(+ real perspective)</i>
              </h2>
              <h2>Make real work.</h2>
            </div>
            <div style={Object.assign({}, style.slide)}>
              <Eyebrow>Our Purpose</Eyebrow>
              <h2>Find the tension.</h2>
              <h2>Make it right.</h2>
              <h2>Deliver. That. Delight.</h2>
            </div>
          </AutoPlaySwipeableViews>
          <Pagination
            dots={3}
            index={index}
            onChangeIndex={this.handleChangeIndex}
          />
        </div>
      </div>
    )
  }
}

export default Slides
