import React from "react"
import Eyebrow from "../Eyebrow"
import Slides from "../Slides"
import Img from "../../../ResponsiveImg"

import styles from "./styles.module.less"

const Mission = () => (
  <>
    {/* <img src={mImage} className={styles.mImage} /> */}
    <div className={styles.mImage}>
      <MissionImage />
    </div>

    <section className={styles.Mission}>
      <div className={styles.textBox}>
        <Eyebrow>Our Mission</Eyebrow>
        <div className={styles.header}>
          <h2>
            It takes action
            <br className={styles.mobileHide} /> to make it mean something.
          </h2>
        </div>
      </div>
      <div className={styles.body}>
        <p>
          It’s not enough to have a statement for a name. Because actions speak
          louder than a word.
        </p>
      </div>
      <Slides />
    </section>
  </>
)

export default Mission

const MissionImage = () => (
  <Img src="/images/about-us-our-mission-image-desktop@3x.jpg" width="1000" />
)
